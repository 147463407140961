import gql from 'graphql-tag';
import { DataProtectionDemand } from '../../types/DataProtectionDemand';

export interface AllDataProtectionDemandResponse {
  demands: DataProtectionDemand[];
}

export const GET_ALL_DATA_PROTECTION_DEMAND = gql`
  query {
    demands: getAllDataProtectionDemands {
      documentId
      dateCreated
      dateProcessed
      documentType
      name
      source
      additionalData {
        key
        value
      },
      deletable
      forgetMeActions
      dataProtectionDemandStatus
      mandatoryNote
    }
  }
`;