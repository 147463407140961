import React, { createContext, useContext, useState, useCallback } from 'react';
import { DataProtectionDemand } from '../../types/DataProtectionDemand';

interface DataProtectionDemandContextType {
  pendingDemands: DataProtectionDemand[];
  processedDemands: DataProtectionDemand[];
  pendingDemandsCount: number;
  processedDemandsCount: number;
  loading: boolean;
  refresh: () => void;
}

const DataProtectionDemandContext = createContext<DataProtectionDemandContextType | undefined>(undefined);

export const useDataProtectionDemandContext = () => {
  const context = useContext(DataProtectionDemandContext);
  if (!context) {
    throw new Error('useDataProtectionDemandContext must be used within a DataProtectionDemandContext');
  }
  return context;
};

export default DataProtectionDemandContext;