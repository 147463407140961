import React, { useCallback, useEffect } from "react";
import DataProtectionDemandContext, { useDataProtectionDemandContext } from "./context";
import useLazyDocHubAdminQuery from "../../hooks/Apollo/useLazyDocHubAdminQuery";
import { AllDataProtectionDemandResponse, GET_ALL_DATA_PROTECTION_DEMAND } from "../../apollo/queries/GetAllDataProtectionDemands";
import { useAccount, useMsal } from "@azure/msal-react";
import { hasClaim } from "../utils/user";

export const DataProtectionDemandContextProvider = ({ children }: { children: React.ReactNode }) => {
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const [getAllDataProtectionDemands, { data: { demands = null } = {}, loading }] = useLazyDocHubAdminQuery<AllDataProtectionDemandResponse>(
    GET_ALL_DATA_PROTECTION_DEMAND,
    { fetchPolicy: "no-cache" }
  );

  useEffect(() => {
    const hasAccess = hasClaim(account, ['DocumentHubSuperAccess']);
    if (hasAccess) {
      getAllDataProtectionDemands();
    }
  }, [getAllDataProtectionDemands, account]);

  const refresh = useCallback(() => {
    getAllDataProtectionDemands();
  }, [getAllDataProtectionDemands]);

  const pendingDemands = demands?.filter((item) => item.dataProtectionDemandStatus.toLocaleLowerCase() === 'pending') || [];
  const pendingDemandsCount = pendingDemands.length;

  const processedDemands = demands?.filter((item) => item.dataProtectionDemandStatus.toLocaleLowerCase() !== 'pending') || [];
  const processedDemandsCount = processedDemands.length;

  return (
    <DataProtectionDemandContext.Provider
      value={{
        pendingDemands,
        pendingDemandsCount,
        processedDemands,
        processedDemandsCount,
        loading,
        refresh,
      }}
    >
      {children}
    </DataProtectionDemandContext.Provider>
  );
};

export { DataProtectionDemandContext, useDataProtectionDemandContext };
export default DataProtectionDemandContext;