import React, { useCallback, useEffect, useState } from 'react';
import { useDataProtectionDemandContext } from '../../contexts/DataProtectionDemandContext';
import { BannerBar, Orientations, Spacer, Spacings } from '@polestar/component-warehouse-react';
import { useHistory } from 'react-router-dom';
import { useMarket } from '@polestar/web3-core-react';

export const DataProtectionDemandBanner = () => {
  const { pendingDemandsCount } = useDataProtectionDemandContext();
  const [showBanner, setShowBanner] = useState(false);
  const history = useHistory();
  const { currentMarket } = useMarket();

  useEffect(() => {
    setShowBanner(pendingDemandsCount > 0);
  }, [pendingDemandsCount]);

  const handleBannerClose = () => {
    setShowBanner(false);
  };

  const handleActionClick = useCallback(() => {
    if (currentMarket?.locale) {
      history.push(`/${currentMarket.locale}/dpr`);
    }
  }, [currentMarket, history]);

  if (!showBanner) return null;

  return (
    <>
      <BannerBar
        message={`There are pending Data Protection Demands (${pendingDemandsCount}).`}
        actionButtonLabel="Click here to review"
        onClose={handleBannerClose}
        onClick={handleActionClick}
        withCloseButton
      />
      <Spacer orientation={Orientations.vertical} spacing={Spacings.xxLarge} />
    </>
  );
};
